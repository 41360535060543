import {Component} from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    private _routeFiltersStorageKey = 'bo-route-filters';

    constructor() {
        localStorage.removeItem(this._routeFiltersStorageKey);
    }
}
