import {NgModule} from '@angular/core';
import {JwtModule} from '@auth0/angular-jwt';

import {AppComponent} from './app.component';
import {HeaderComponent} from './navigation/header/header.component';
import {SidenavListComponent} from './navigation/sidenav-list/sidenav-list.component';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CurrentOrgInterceptor} from './_interceptors/current-org-interceptor';
import {SharedModule} from './shared/shared.module';
import {environment} from '../environments/environment';

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
        full: 'MMMM DD, YYYY'
    }
};

export function getToken() {
    return localStorage.getItem('lido-bo-token');
}

function _allowedDomains(): string[] {
    let domains: string[] = [];
    domains = domains.concat(['http://lidoapi.org', 'https://lidoapi.org']);
    if (!environment.production) domains.push('localhost:8888');
    return domains;
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SidenavListComponent,
    ],
    imports: [
        BrowserModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: getToken,
                allowedDomains: _allowedDomains(),
                disallowedRoutes: ['localhost:8888/api/auth', 'http://lidoapi.org/api/auth', 'https://lidoapi.org/api/auth'],
            }
        }),
    ],
    providers: [
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {useUtc: true}
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        {provide: HTTP_INTERCEPTORS, useClass: CurrentOrgInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
