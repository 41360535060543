import {UserPermission} from '../_models/UserPermission';
import {Organization} from './../_models/Organization';
import {environment} from '../../environments/environment';
import {LoginDto} from '../_dtos/auth/loginDto';
import {AlertifyService} from './alertify.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {User} from '../_models/User';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {UserRegistrationDto} from '../_dtos/auth/UserRegistrationDto';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    baseUrl = environment.apiUrl + 'auth/';
    jwtHelper = new JwtHelperService();
    user: User;
    respectsSuperUser = environment.respectSuper;
    isLoggedIn = false;
    isLoggedInSub = new Subject<boolean>();
    currentOrganization: Organization;
    currentOrganizationSub = new Subject<Organization>();
    private _tokenStorageName = 'lido-bo-token';
    private _currentOrgStorageName = 'lido-bo-current-org';

    constructor(
        private http: HttpClient,
        private afy: AlertifyService,
        private router: Router
    ) {
        if (this.user == null && !this.isLoggedIn) {
            const token = this._getToken();
            if (this.jwtHelper.isTokenExpired(token)) return;

            if (token != null) {
                this.user = this._mapTokenToUser(token);

                if (this.user != null) {
                    this.isLoggedIn = true;
                    this.isLoggedInSub.next(true);
                    const currentOrganization = this._getCurrentOrganization();

                    if (currentOrganization != null) {
                        this.currentOrganization = currentOrganization;
                        this.currentOrganizationSub.next(this.currentOrganization);
                    } else {
                        this.currentOrganizationSub.next(null);
                    }
                }

            } else {
                this.isLoggedInSub.next(false);
            }
        }

        localStorage.removeItem('lido-backoffice-user');
        localStorage.removeItem('tempInitialInventory');
        localStorage.removeItem('lido-backoffice-organization');
        localStorage.removeItem('lido-backoffice-token');
    }

    login(dto: LoginDto) {
        this.http.post(this.baseUrl + 'login', dto).subscribe(
            (res: any) => {
                const token = res.token;
                if (token == null) {
                    this.afy.error('Login failed');
                    this.router.navigate(['/']);
                    this.isLoggedInSub.next(false);
                    return;
                }

                this.user = this._mapTokenToUser(token);
                if (this.user == null) {
                    this.afy.error('Login failed');
                    this.router.navigate(['/']);
                    this.isLoggedInSub.next(false);
                    return;
                }

                localStorage.setItem(this._tokenStorageName, token);

                this.isLoggedIn = true;
                this.isLoggedInSub.next(true);

                if (this.user.organizations.length === 1) {
                    this.currentOrganization = this.user.organizations[0];
                    this.currentOrganizationSub.next(this.currentOrganization);
                    localStorage.setItem(this._currentOrgStorageName, JSON.stringify(this.currentOrganization));
                }

                this.afy.success('Login successful');
                this.router.navigate(['/']);
            },
            error => {
                this.afy.error(error.error);
                this.router.navigate(['/']);
                this.isLoggedInSub.next(false);
            }
        );
    }

    signup(dto: UserRegistrationDto): Observable<any> {
        return this.http.post(this.baseUrl + 'register/', dto);
    }

    logout() {
        localStorage.removeItem(this._currentOrgStorageName);
        localStorage.removeItem(this._tokenStorageName);
        this.user = null;
        this.currentOrganization = null;
        this.currentOrganizationSub.next(this.currentOrganization);

        this.isLoggedIn = false;
        this.isLoggedInSub.next(false);

        this.afy.success('Successfully logged out');
        this.router.navigate(['/']);
    }

    setCurrentOrganization(organizationName: string) {
        if (!this.isLoggedIn) return;
        if (this.user == null) return;

        const org = this.user.organizations.find(x => x.name === organizationName);
        if (org == null) return;

        this.currentOrganization = org;
        localStorage.setItem(this._currentOrgStorageName, JSON.stringify(this.currentOrganization));
        this.currentOrganizationSub.next(this.currentOrganization);

        this.afy.success('organization changed successfully');
        this.router.navigate(['/']);
    }

    getCurrentOrganizationId(): number {
        const currentOrg = this._getCurrentOrganization();
        if (!currentOrg) return null;

        return currentOrg.id;
    }

    hasPermission(permission: string): boolean {
        if (!this.isLoggedIn || this.user == null) return false;
        if (this.respectsSuperUser && this.user.role === 'SuperUser') return true;

        const userPermission = this.user.userPermissions
            .find(x => x.permission.name === permission && x.organizationId === this.getCurrentOrganizationId());
        if (userPermission == null) return false;

        if (userPermission.expireDate == null) return true;
        return moment().isBefore(userPermission.expireDate);
    }

    private _mapTokenToUser(token: string): User {
        try {
            const decodedToken = this.jwtHelper.decodeToken(token);
            if (decodedToken == null) return null;

            const user: User = {
                id: decodedToken.nameid,
                username: decodedToken.unique_name,
                role: decodedToken.role,
                isActive: true,
                email: '',
                userPermissions: [],
                organizations: []
            };

            const tokenFieldName = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication';

            if (decodedToken.groupsid != null && decodedToken.groupsid.length) {
                const orgs: Organization[] = JSON.parse(decodedToken.groupsid);
                user.organizations = orgs.map(o => {
                    return {
                        id: o['Id'],
                        name: o['Name'],
                        contactNumber: '',
                        organizationType: o['OrganizationType'],
                        minimumPaymentTerm: 0,
                        hasInventory: false,
                        canReconcileShipments: false,
                        canAuthenticatePayments: false,
                        exclusiveForName: null,
                        organizationProducts: null,
                        paymentTerms: null,
                        isCountingInventory: false
                    };
                });
            }

            if (decodedToken[tokenFieldName] != null && decodedToken[tokenFieldName].length) {
                const userPermissions: UserPermission[] = JSON.parse(decodedToken[tokenFieldName]);
                user.userPermissions = userPermissions.map(p => {
                    return {
                        permission: {
                            name: p['Permission'],
                            permissionCategory: ''
                        },
                        permissionId: 0,
                        organizationId: p['OrganizationId'],
                        userId: 0,
                        organizationName: p['OrganizationName'],
                        expireDate: p['ExpireDate']
                    };
                });
            }

            return user;
        } catch {
            return null;
        }
    }

    private _getCurrentOrganization(): Organization {
        const storedOrganization = localStorage.getItem(this._currentOrgStorageName);

        if (storedOrganization) {
            const org: Organization = JSON.parse(storedOrganization);
            return org;
        }

        return null;
    }

    private _getToken() {
        return localStorage.getItem(this._tokenStorageName);
    }
}
