<mat-toolbar color="primary">
    <div>
        <button (click)="onToggleSidenav()" mat-icon-button>
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <div>
        <a [routerLink]="['/']" class="ml-2 logo" routerLinkActive="active">
            LIDO
        </a>
    </div>

    <div fxFlex></div>
    <div *ngIf="user?.organizations?.length && isLoggedIn">

        <button [matMenuTriggerFor]="orgs" mat-button>
            <mat-icon>store</mat-icon>
            <span *ngIf="currentOrg">Acting for {{currentOrg.name}}</span>
            <span *ngIf="!currentOrg">Select Organization</span>
        </button>

        <mat-menu #orgs="matMenu">
            <button (click)="selectOrganization(org.name)" *ngFor="let org of user?.organizations" mat-menu-item>
                {{org.name}}
            </button>
        </mat-menu>
    </div>

    <div fxHide.xs fxLayout fxLayoutAlign="flex-end">
        <ul class="navigation-items" fxLayout fxLayoutGap="20px">
            <li *ngIf="!isLoggedIn"><a [routerLink]="['/login']" routerLinkActive="active">Login</a></li>
            <li *ngIf="!isLoggedIn"><a [routerLink]="['/signup']" routerLinkActive="active">Signup</a></li>
            <li *ngIf="isLoggedIn"><a (click)="logout()" style="cursor: pointer">Logout</a></li>
        </ul>
    </div>
</mat-toolbar>
