<mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
        <app-sidenav-list (closeSidenav)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column">
        <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
        <main fxFlex>
            <router-outlet></router-outlet>
        </main>
    </mat-sidenav-content>
</mat-sidenav-container>
