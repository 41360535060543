import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {SidenavLink} from '../_models/SidenavLink';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class SidenavService {
    sectionLinksSub = new Subject<SidenavLink[]>();
    navLinksSub = new Subject<SidenavLink[]>();

    constructor(private auth: AuthService) {
    }

    updateLinks(links: any[]) {
        this.createNavLinks();
        this.sectionLinksSub.next(links);
    }

    private createNavLinks() {
        const navLinks = [
            {name: 'Home', href: ['/'], icon: 'home'},
            {name: 'Orders', href: ['/orders'], icon: 'assignment'},
            {name: 'Receiving', href: ['/receiving'], icon: 'archive'},
            {name: 'Shipment', href: ['/shipment'], icon: 'local_shipping'},
            {name: 'Reconciliation', href: ['/recon'], icon: 'compare_arrows'},
            {name: 'Inventory', href: ['/inventory'], icon: 'view_module'},
            {name: 'Suppliers', href: ['/suppliers'], icon: 'contacts'},
            {name: 'Invoices', href: ['/invoices'], icon: 'folder_open'},
            {name: 'Payments', href: ['/payments'], icon: 'account_balance_wallet'},
            {name: 'Reports', href: ['/reports'], icon: 'visibility'},
            {name: 'My Organization', href: ['/my-organization'], icon: 'groups'},
        ];

        const user = this.auth.user;

        if (user) {
            if (user.role === 'SuperUser') {
                navLinks.push({name: 'Users', href: ['/users'], icon: 'lock'});
                navLinks.push({name: 'Products', href: ['/product'], icon: 'lock'});
                navLinks.push({name: 'Organizations', href: ['/organizations'], icon: 'lock'});
            }
        }

        this.navLinksSub.next(navLinks.slice());
    }
}
