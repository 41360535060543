<mat-nav-list fxLayout="column">
    <a (click)="onClose()" mat-list-item>
        <mat-icon class="mr-2">menu</mat-icon>
        Close navigation
    </a>

    <section *ngIf="links?.length && loggedIn()" class="section-navigation" fxLayout="column">
        <hr/>
        <a (click)="onClose()" *ngFor="let link of links" [routerLink]="link.href" mat-list-item
           routerLinkActive="active">
            <mat-icon class="mr-2">{{link.icon}}</mat-icon>
            <span>{{link.name}}</span>
        </a>
    </section>

    <section *ngIf="loggedIn()" class="site-navigation">
        <hr/>

        <a (click)="onClose()" *ngFor="let link of navLinks" [routerLink]="link.href" mat-list-item
           routerLinkActive="active">
            <mat-icon class="mr-2">{{link.icon}}</mat-icon>
            <span>{{link.name}}</span>
        </a>
    </section>

    <hr/>

    <a (click)="onClose()" *ngIf="!loggedIn()" [routerLink]="['/login']"
       mat-list-item routerLinkActive="active">
        <mat-icon class="mr-2">input</mat-icon>
        <span>Login</span>
    </a>
    <a (click)="onClose()" *ngIf="!loggedIn()" [routerLink]="['/signup']" mat-list-item routerLinkActive="active">
        <mat-icon class="mr-2">face</mat-icon>
        <span>Signup</span>
    </a>

    <mat-list-item *ngIf="loggedIn()">
        <button (click)="logout()" mat-icon-button>
            <mat-icon class="mr-2">eject</mat-icon>
            <span>Logout</span>
        </button>
    </mat-list-item>

</mat-nav-list>
