import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(x => x.HomeModule)
    },
    {path: 'login', loadChildren: () => import('./login/login.module').then(x => x.LoginModule)},
    {path: 'signup', loadChildren: () => import('./signup/signup.module').then(x => x.SignupModule)},
    {
        path: 'orders',
        loadChildren: () =>
            import('./orders/orders.module').then(x => x.OrdersModule),
    },
    {
        path: 'receiving',
        loadChildren: () =>
            import('./receiving/receiving.module').then(x => x.ReceivingModule),
    },
    {
        path: 'shipment',
        loadChildren: () =>
            import('./shipment/shipment.module').then(x => x.ShipmentModule),
    },
    {
        path: 'recon',
        loadChildren: () =>
            import('./reconciliation/reconciliation.module').then(x => x.ReconciliationModule),
    },
    {
        path: 'inventory',
        loadChildren: () =>
            import('./inventory/inventory.module').then(m => m.InventoryModule),
    },
    {
        path: 'users',
        loadChildren: () =>
            import('./users/users.module').then(x => x.UsersModule),
    },
    {
        path: 'suppliers',
        loadChildren: () =>
            import('./suppliers/suppliers.module').then(x => x.SuppliersModule),
    },
    {
        path: 'invoices',
        loadChildren: () =>
            import('./invoice/invoice.module').then(m => m.InvoiceModule),
    },
    {
        path: 'payments',
        loadChildren: () =>
            import('./payments/payments.module').then(m => m.PaymentsModule),
    },
    {
        path: 'organizations',
        loadChildren: () =>
            import('./organizations/organizations.module').then(x => x.OrganizationsModule),
    },
    {
        path: 'product',
        loadChildren: () =>
            import('./products/products.module').then(x => x.ProductsModule),
    },
    {
        path: 'reports',
        loadChildren: () =>
            import('./reports/reports.module').then(x => x.ReportsModule),
    },
    {
        path: 'my-organization',
        loadChildren: () =>
            import('./my-organization/my-organization.module').then(x => x.MyOrganizationModule),
    },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
