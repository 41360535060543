import {Organization} from '../../_models/Organization';
import {Subscription} from 'rxjs';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AuthService} from 'src/app/_services/auth.service';
import {User} from 'src/app/_models/User';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Output() sidenavToggle = new EventEmitter<void>();
    user: User;

    currentOrgSub: Subscription;
    currentOrg: Organization;

    isLoggedInSub: Subscription;
    isLoggedIn: boolean;

    constructor(private auth: AuthService) {
    }

    ngOnInit() {
        this.isLoggedIn = this.auth.isLoggedIn;
        this.user = this.auth.user;
        if (this.user != null) {
            this.user.organizations = this.user.organizations?.sort((a, b) => (a.name > b.name) ? 1 : -1);
        }
        this.currentOrg = this.auth.currentOrganization;

        this.isLoggedInSub = this.auth.isLoggedInSub
            .subscribe(isLoggedIn => {
                this.isLoggedIn = isLoggedIn;
                this.user = this.auth.user;
                if (this.user != null) {
                    this.user.organizations = this.user?.organizations.sort((a, b) => (a.name > b.name) ? 1 : -1);
                }
                this.currentOrg = this.auth.currentOrganization;
            });

        this.currentOrgSub = this.auth.currentOrganizationSub
            .subscribe((org: Organization) => {
                this.currentOrg = org;
            });
    }

    ngOnDestroy() {
        this.isLoggedInSub.unsubscribe();
        this.currentOrgSub.unsubscribe();
    }

    onToggleSidenav() {
        this.sidenavToggle.emit();
    }

    logout() {
        this.auth.logout();
        this.user = null;
    }

    selectOrganization(organizationName: string) {
        this.auth.setCurrentOrganization(organizationName);
    }
}
